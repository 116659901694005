/* FuturePlansSection.module.css */

.futurePlansSection {
  margin: 2rem 0;
  color: #fff;
}

/* Heading */
.heading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #00e6a7;
}

.headingIcon {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Category block */
.categoryBlock {
  margin-bottom: 1.5rem;
  color: #00e6a7;
}

.categoryTitle {
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
  border-bottom: 2px solid #00e6a7;
  padding-bottom: 0.5rem;
}

/* Card */
.card {
  background-color: #1f1f1f;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.cardTop {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #fff
}

.iconBox {
  width: 40px;
  height: 40px;
  background-color: #2b2b2b;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconBox svg {
  font-size: 1rem;
  color: #00e6a7;
}

.cardTitle {
  font-size: 1rem;
  font-weight: 600;
}

.provider {
  font-size: 0.9rem;
  color: #ccc;
}

.plannedDate {
  font-size: 0.85rem;
  color: #999;
}

/* Show More / Show Less */
.toggleCategory {
  text-align: left;
  color: #00e6a7;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* Show Description */
.showDesc {
  color: #00e6a7;
  font-size: 0.9rem;
  cursor: pointer;
}

.description {
  color: #ccc;
  font-size: 0.85rem;
  padding-top: 0.5rem;
}

/* Responsive tweaks */
@media (max-width: 768px) {
  .categoryTitle {
    font-size: 1rem;
  }

  .card {
    padding: 0.75rem;
  }

  .cardTop {
    flex-direction: column;
    align-items: flex-start;
  }

  .showDesc {
    text-align: left;
  }
}
