/* ========== DESKTOP STYLES ========== */
.floating-sidebar {
  position: fixed;
  top: 50%;
  left: 3.5rem;
  transform: translateY(-70%);
  width: 200px;
  background-color: #1f1f1f;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* The hamburger icon (hidden on desktop) */
.hamburger-btn {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
}

/* Desktop menu */
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%; 
}

.sidebar-menu li {
  margin: 1rem 0;
}

.sidebar-menu a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: color 0.2s;
}

.sidebar-menu a:hover {
  color: #00e6a7;
}

/* Styling for menu icons */
.menu-icon {
  margin-right: 0.5rem;
}


/* ========== MOBILE (≤768px) ========== */
@media (max-width: 768px) {
  .floating-sidebar {
    position: sticky;
    top: 60px;
    left: 0;
    transform: none;
    width: 100%;
    border-radius: 0;
    padding: 0;
    display: block;
    z-index: 1001;
  }

  /* Hamburger icon styling */
  .hamburger-btn {
    display: block;
    position: absolute;
    top: -2.75rem;
    left: 1rem;
    font-size: 1.5rem;
    color: #fff;
    z-index: 1001;
    margin-bottom: 0;
  }

  /* The menu container */
  .sidebar-menu {
    display: none;
    text-align: left;
    background-color: #1f1f1f;
    width: 100%;
    padding: 1rem 0;
    margin: 0 auto;
    padding-left: 2rem;
  }

  .sidebar-menu li {
    margin: 0.5rem 0;
  }

  /* Show menu when isOpen */
  .sidebar-menu.open {
    display: block;
  }
}
