/* Hobbies Section */
.hobbiesSection {
  margin: 2rem 0;
  color: #fff;
}

/* Heading */
.heading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #00e6a7;
}

.headingIcon {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Grid Layout - 4 cards per row on desktop */
.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr)); /* 4 per row */
  gap: 1.5rem;
  justify-content: center;
}

/* Hobby Card */
.hobbyCard {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
}

.hobbyCard:hover {
  transform: scale(1.03);
}

/* Hobby Header */
.hobbyHeader {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.hobbyHeader svg {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Hobby Title */
.hobbyTitle {
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
}

/* Hobby Description */
.hobbyDescription {
  font-size: 0.95rem;
  color: #ccc;
  line-height: 1.5;
}

/* RESPONSIVE DESIGN */

@media (max-width: 10000px) {
  .grid {
    grid-template-columns: repeat(4, minmax(250px, 1fr)); /* 4 per row */
  }
  .hobbyCard:nth-child(5){
    transform: translateX(107%);
  }
  .hobbyCard:nth-child(6){
    transform: translateX(107%);
  }
}

@media (max-width: 1675px) {
  .grid {
    grid-template-columns: repeat(4, minmax(200px, 1fr)); /* 3 per row */
  }
}

@media (max-width: 1475px) {
  .grid {
    grid-template-columns: repeat(3, minmax(250px, 1fr)); /* 3 per row */
  }
  .hobbyCard:nth-child(5){
    transform: translateX(0%);
  }
  .hobbyCard:nth-child(6){
    transform: translateX(0%);
  }
}

@media (max-width: 1275px) {
  .grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr)); /* 2 per row */
  }
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, minmax(250px, 1fr)); /* 2 per row */
  }
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(1, minmax(250px, 1fr)); /* 1 per row */
  }
}