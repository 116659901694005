/* src/components/Header.css */

.site-header {
  height: 60px;
  position: sticky;  /* or fixed, if you prefer */
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center; /* center “Sagar’s Portfolio” horizontally */
  background-color: #1f1f1f;
  box-shadow: 0 2px 5px rgba(0,0,0,0.5);
}

.logo {
  font-size: 1.2rem;
  font-weight: 600;
  color: #00e6a7;
}

.header-right {
  color: #fff;
  font-size: 0.95rem;
}

/* Example media query: reduce padding on narrower screens */
@media (max-width: 600px) {
  .site-header {
    padding: 0.5rem;
    box-shadow: none;
  }
  .logo {
    font-size: 1.1rem;
  }
}
