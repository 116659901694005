/* App.css */

/* Basic reset / box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #121212; /* Dark background */
  color: #ffffff;
}

/* The top-level container: header on top, then content below */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  position: relative;
}

.main-content {
  margin-left: 300px; /* desktop offset for fixed sidebar */
  padding: 1rem;
  padding-right: 17.5rem;
}

/* RESPONSIVE ADJUSTMENTS */
@media (max-width: 1375px) {
  .main-content {
    padding-right: 10rem;
  }
}

@media (max-width: 1150px) {
  .main-content {
    padding-right: 5rem;
  }
}

/* RESPONSIVE ADJUSTMENTS */
@media (max-width: 1024px) {
  .main-content {
    margin-left: 250px; /* Reduce offset for tablets */
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0; /* No offset on mobile */
    padding: 1.2rem;
  }
}

/* Ensure Hero Section spans full width */
.hero-section {
  padding-right: 0 !important;
}
