.summarySection {
  margin: 2rem 0;
  color: #fff;
}

/* Heading */
.heading {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #00e6a7;
}

.headingIcon {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Summary Content */
.summaryContent {
  background-color: #1f1f1f;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  line-height: 1.6;
}

/* Highlights */
.skillHighlights, .notableProjects, .summaryContent{
  margin-top: 1.5rem;
}

.skillHighlights h4, .notableProjects h4, .summaryContent h4 {
  color: #00e6a7;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.skillHighlights ul, .notableProjects ul {
  padding: 0;
  list-style: none;
}

.skillHighlights li, .notableProjects li {
  background-color: #2b2b2b;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Closing Remark */
.closingRemark {
  margin-top: 1.5rem;
  font-size: 1rem;
  font-style: italic;
  text-align: center;
}

/* ========== MOBILE VIEW (≤768px) ========== */
@media (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
  }

  .summaryContent {
    padding: 1.2rem;
  }

  .skillHighlights h4, .notableProjects h4 {
    font-size: 1rem;
  }

  .skillHighlights li, .notableProjects li {
    font-size: 0.9rem;
  }
}
