.hero-section {
  position: relative;
  margin-bottom: 2rem;
}

.hero-image {
  width: 100%;
  height: 300px;
  background: url("../assets/banner.jpg") center/cover no-repeat;
}

.hero-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
}

.hero-left h2 {
  margin: 0 0 0.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #00e6a7;
}

.hero-location {
  margin: 0;
  color: #ccc;
  font-size: 0.95rem;
}

.hero-right {
  margin-left: auto;
}

.contact-btn {
  background-color: #00e6a7;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.6rem 1.2rem;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
.contact-btn:hover {
  background-color: #00c494;
}

/* Media query: shrink hero height on small screens, or allow auto height */
@media (max-width: 600px) {
  .hero-image {
    height: 200px;
  }
  .hero-left h2 {
    font-size: 1.2rem;
  }
  .contact-btn {
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
  }
}
