/* Languages Section */
.languagesSection {
  margin: 2rem 0;
  color: #fff;
}

/* Heading */
.heading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #00e6a7;
}

.headingIcon {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Grid Layout - 3 columns per row on desktop */
.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr)); /* 3 per row */
  gap: 1.5rem;
  justify-content: center;
}

/* Language Card */
.languageCard {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.languageCard:hover {
  transform: scale(1.03);
}

/* Progress Bar (Moved to Top) */
.progressBar {
  background-color: #333;
  border-radius: 6px;
  height: 12px;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-bottom: 1rem; /* Spacing below progress bar */
}

.progressFill {
  background-color: #00e6a7;
  height: 100%;
  transition: width 0.3s ease;
}

/* Language Title */
.langName {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.3rem;
}

/* Language Level */
.langLevel {
  font-size: 1rem;
  font-weight: 500;
  color: #00e6a7;
}

/* RESPONSIVE DESIGN */

/* 3 Cards Layout - Last Card Centered */
@media (max-width: 1350px) {
  .grid {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
}

/* 2 Cards Layout - Last Card Centered */
@media (max-width: 950px) {
  .grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .languageCard:nth-child(3) {
    transform: translateX(50%);
  }
}

/* 2 Columns for tablets */
@media (max-width: 820px) {
  .grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

/* 1 Column for mobile */
@media (max-width: 475px) {
  .grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .languageCard:nth-child(3) {
    transform: translateX(0%);
  }
}
