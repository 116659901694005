/* Global reset & default styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #121212;
  color: #ffffff;
  line-height: 1.6;
}

/* Utility container to wrap content */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Sections */
.section {
  padding: 4rem 0;
}

/* Buttons */
.btn {
  display: inline-block;
  background-color: #00e6a7;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.btn:hover {
  background-color: #00c494;
}

/* Scroll Behavior */
html {
  scroll-behavior: smooth;
  scroll-margin: 1rem;
}

/* RESPONSIVE ADJUSTMENTS */
@media (max-width: 1024px) {
  .section {
    padding: 3rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 2.5rem 1rem;
  }
}
