/* ProjectSection.module.css */

.projectSection {
  margin: 2rem 0;
  color: #fff;
}

/* Heading Row */
.heading {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  text-align: left;
  justify-content: left;
  color: #00e6a7;
}

.headingIcon {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Category Block */
.categoryBlock {
  margin-bottom: 2rem;
}

/* Category Title */
.categoryTitle {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #00e6a7;
  margin-bottom: 1rem;
}

/* Card Container */
.card {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/* Top Part of Card (Title + Date) */
.cardTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

/* Title */
.cardTitle {
  font-size: 1.1rem;
  font-weight: bold;
}

/* Date */
.date {
  font-size: 0.9rem;
  color: #ccc;
}

/* Description */
.description {
  font-size: 0.95rem;
  color: #ccc;
  line-height: 1.4;
}

/* Skills */
.skills {
  font-size: 0.9rem;
  color: #00e6a7;
}

.toggleCategory {
  text-align: left;
  color: #00e6a7;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* ========== MOBILE VIEW (≤768px) ========== */
@media (max-width: 768px) {
  .categoryTitle {
    font-size: 1.1rem;
  }

  .card {
    padding: 1.2rem;
  }

  .cardTitle {
    font-size: 1rem;
  }

  .date {
    font-size: 0.85rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .skills {
    font-size: 0.85rem;
  }
}
