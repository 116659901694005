/* OverviewSection.module.css */

.overviewSection {
  margin: 2rem 0;
  color: #fff;
  text-align: center;
}

/* Heading */
.heading {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #00e6a7;
}

.headingIcon {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Grid Layout */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  max-width: 100%;
}

/* Card */
.card {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card:hover {
  transform: scale(1.02);
}

/* Icon */
.icon {
  font-size: 2rem;
  color: #00e6a7;
  margin-bottom: 1rem;
}

/* Title */
.cardTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

/* Subtitle */
.cardSubtitle {
  font-size: 1rem;
  font-weight: 500;
  color: #ccc;
  margin-bottom: 0.5rem;
}

/* Description */
.cardDescription {
  font-size: 0.9rem;
  color: #aaa;
  line-height: 1.5;
}

/* ========== RESPONSIVE DESIGN ========== */

/* 3 columns for medium screens */
@media (max-width: 1665px) {
  .gridContainer {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
  }
}

/* 3 columns for medium screens */
@media (max-width: 1475px) {
  .gridContainer {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
  .card:nth-child(7){
    transform: translateX(50%);
  }
  .card:nth-child(8){
    transform: translateX(50%);
  }
}
/* 3 columns for medium screens */
@media (max-width: 1475px) {
  .gridContainer {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
  .card:nth-child(7){
    transform: translateX(50%);
  }
  .card:nth-child(8){
    transform: translateX(50%);
  }
}
/* 3 columns for medium screens */
@media (max-width: 1350px) {
  .gridContainer {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
  .card:nth-child(7){
    transform: translateX(0%);
  }
  .card:nth-child(8){
    transform: translateX(0%);
  }
}

/* 2 columns for tablets */
@media (max-width: 1150px) {
  .gridContainer {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

/* 1 column for mobile */
@media (max-width: 800px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}
