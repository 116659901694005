.scroll-to-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 50px;
    height: 50px;
    background-color: #00e6a7;
    color: #fff;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    transform: translateY(20px);
    z-index: 1000; /* Ensures it's above most content */
  }
  
  .scroll-to-top.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .scroll-to-top:hover {
    background-color: #00c494;
  }
  
  @media (max-width: 600px) {
    .scroll-to-top {
      width: 45px;
      height: 45px;
      font-size: 1.2rem;
    }
  }
  