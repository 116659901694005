/* SkillsSection.module.css */

.skillsSection {
  margin: 2rem 0;
  color: #fff;
}

/* Heading */
.heading {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  text-align: left;
  justify-content: left;
  color: #00e6a7;
}

.headingIcon {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Grid Layout */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  gap: 1.5rem;
}

/* Skill Card */
.skillCard {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
}
.skillCard:nth-child(5){
  transform: translateX(55%);
}

.skillCard:nth-child(6){
  transform: translateX(55%);
}

.skillCard:nth-child(7){
  transform: translateX(55%);
}

/* Category Title */
.categoryTitle {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #00e6a7;
  margin-bottom: 1rem;
}

/* Skill List */
.skillList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.skillItem {
  background-color: #2b2b2b;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Show More Button */
.toggleButton {
  text-align: center;
  margin-top: 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  color: #00e6a7;
  text-decoration: underline;
}

.toggleButton:hover {
  color: #00c497;
}


@media (max-width: 1860px) {
  .gridContainer{
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
  .skillCard:nth-child(5){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(6){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(7){
    transform: translateX(106%);
  }
}

@media (max-width: 1700px) {
  .gridContainer{
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
  .skillCard:nth-child(5){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(6){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(7){
    transform: translateX(107%);
  }
}

@media (max-width: 1560px) {
  .gridContainer{
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
  .skillCard:nth-child(5){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(6){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(7){
    transform: translateX(108%);
  }
}

@media (max-width: 1550px) {
  .gridContainer {
    grid-template-columns: repeat(2, minmax(250px, 1fr)); /* 3 per row */
  }
  .skillCard:nth-child(5){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(6){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(7){
    transform: translateX(55%);
  }
}

@media (max-width: 1050px) {
  .gridContainer {
    grid-template-columns: repeat(1, minmax(200px, 1fr)); /* 3 per row */
  }
  .skillCard:nth-child(5){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(6){
    transform: translateX(0%);
  }
  
  .skillCard:nth-child(7){
    transform: translateX(0%);
  }
}


/* ========== MOBILE VIEW (≤768px) ========== */
@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }

  .categoryTitle {
    font-size: 1.1rem;
  }

  .skillItem {
    font-size: 0.85rem;
    padding: 0.4rem 0.8rem;
  }
}
