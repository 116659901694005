/* Timeline Section */
.timelineSection {
  margin: 2rem 0;
  color: #fff;
}

/* Heading */
.heading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #00e6a7;
}

.headingIcon {
  font-size: 1.5rem;
  color: #00e6a7;
}

/* Timeline Container */
.timelineContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2rem 1fr; /* Left Col | Line + Dot | Right Col */
  align-items: center;
  row-gap: 2rem;
}

/* Vertical timeline line */
.timelineContainer::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  background-color: #444;
  z-index: 0;
}

/* Row - Each timeline entry */
.row {
  display: contents;
}

/* Left Column (Side = Left) */
.leftCol {
  grid-column: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Right Column (Side = Right) */
.rightCol {
  grid-column: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Center Column - Line + Dot */
.centerCol {
  grid-column: 2;
  position: relative;
  width: 2rem;
  display: flex;
  justify-content: center;
}

/* Dot - Centered on each row */
.dot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #00e6a7;
  border-radius: 50%;
  z-index: 1;
}

/* Timeline Card */
.cardLink {
  color: inherit;
  text-decoration: none;
  max-width: 300px;
  width: 100%;
}

.card {
  background-color: #1f1f1f;
  border-radius: 6px;
  padding: 1rem 1.2rem;
  max-width: 300px;
  box-sizing: border-box;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card:hover {
  transform: scale(1.02);
}

/* Card Icon */
.cardIcon {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #2b2b2b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.cardIcon svg {
  font-size: 1rem;
  color: #00e6a7;
}

/* Card Text */
.cardTitle {
  margin: 0 0 0.25rem;
  font-size: 1rem;
  font-weight: 600;
}

.cardSubtitle {
  margin: 0;
  color: #ccc;
  font-size: 0.95rem;
}

.cardDate {
  margin-top: 0.5rem;
  color: #999;
  font-size: 0.85rem;
}

/* “Show Description” / “Hide Description” link */
.toggleDesc {
  color: #00e6a7;
  font-size: 0.9rem;
  cursor: pointer;
  margin: 0.5rem 0;
  display: inline-block;
}

.separator {
  border: none;
  border-top: 1px solid #444;
  margin: 0.75rem 0;
}

.detailsList {
  margin: 0.5rem 0 0.75rem 1.2rem;
  padding: 0;
  list-style: disc;
  color: #ccc;
  line-height: 1.4;
  text-align: left;
}


/* ========== MOBILE VIEW (≤768px) ========== */
@media (max-width: 768px) {
  .timelineContainer {
    grid-template-columns: 2rem 1fr; /* Line in Col1, Items in Col2 */
    row-gap: 2rem; /* Ensure spacing between items */
  }

  /* Adjust timeline line for mobile */
  .timelineContainer::before {
    left: 1rem; /* Keep dots on the left */
    transform: none;
    height: 100%;
  }

  .centerCol {
    grid-column: 1;
    position: relative;
  }

  /* Ensure dots align with the middle of the cards */
  .dot {
    left: 1rem;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  /* Both LeftCol & RightCol move into Col2 on mobile */
  .leftCol,
  .rightCol {
    grid-column: 2;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  /* Increase spacing between cards */
  .row {
    margin-bottom: 1.5rem;
  }
  
  .cardLink {
    max-width: 100%;
    width: 100%;
  }
  
  /* Ensure card width adapts for mobile */
  .card {
    max-width: 100%;
    min-height: 100px; /* Ensures uniform height for dot alignment */
    display: flex;
    align-items: center;
  }
}
